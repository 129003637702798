<template>
  <div class="text-center mt-2" v-if="!dataLoaded">
    <v-progress-circular :size="50" color="primary" indeterminate/>
  </div>

  <div v-else>
    <v-row class="my-0">
      <v-col cols="12" class="py-0">
        <v-card class="base-card pa-5">
          <div class="d-flex flex-wrap cycle-buttons">
            <v-btn
              :loading="cycleAttributesLoading"
              small
              color="primary"
              class="white--text px-4"
              @click="getCycleAttributes"
            >
              <v-icon left small>mdi-tune-variant</v-icon>
              Атрибуты цикла
            </v-btn>

            <div>
              <span
                :title="sendToAxBtnTitle"
                :class="{ 'cursor-help': disabledSendToAxBtn }"
              >
                <v-btn
                  v-if="!isCycleCanceled && !(isAdmin || isSuperAdmin)"
                  :disabled="disabledSendToAxBtn"
                  small
                  color="primary"
                  class="white--text px-4"
                  :loading="sendToAxLoading"
                  @click="sendToAx"
                >
                  <v-icon left size="18">mdi-account-arrow-down-outline</v-icon>Выгрузить в учебное управление
                </v-btn>
              </span>

              <v-btn
                v-if="!isCycleCanceled && (isAdmin || isSuperAdmin)"
                small
                color="primary"
                class="white--text px-4"
                :loading="sendToAxLoading"
                @click="mergeResultDoc"
              >
                <v-icon left size="18">mdi-account-arrow-down-outline</v-icon>
                Выгрузить данные по итоговым документам
              </v-btn>
            </div>

            <v-btn
              v-if="!isCycleCanceled"
              small
              color="primary"
              class="white--text px-4"
              :loading="listForMoodleLoading"
              @click="getDpoListenerListForMoodleExcel"
            >
              <v-icon left size="18">mdi-file-download-outline</v-icon>
              Данные о слушателях
            </v-btn>

            <div>
              <v-btn
                v-if="!isCycleCanceled"
                small
                outlined
                color="primary"
                class="white--text px-4 mr-4"
                @click="goToListenersPage"
              >
                <v-icon left size="18">mdi-account-group-outline</v-icon>
                Слушатели
              </v-btn>

              <ReportMenu
                v-if="!isCycleCanceled"
                class="print-margin-left"
                btnTitle="Печать"
                :cycleId="cycleId"
              />
            </div>
          </div>

          <div class="inputs" v-if="!isCycleCanceled">
            <v-autocomplete
              :loading="headTeacherLoading"
              v-model="headTeacher"
              label="Заведующий учебной частью"
              placeholder="Выберите значение из списка"
              :items="employeesList"
              item-text="employeeInfo"
              hide-no-data
              hide-selected
              return-object
              clearable
              @change="setHeadTeacher"
            />

            <!--            <v-text-field-->
            <!--              :loading="emailLoading"-->
            <!--              v-model="email"-->
            <!--              label="E-mail заведующего учебной частью (заведующего кафедрой)"-->
            <!--              :error-messages="emailErrors"-->
            <!--              @input="$v.email.$touch()"-->
            <!--              @blur="$v.email.$touch()"-->
            <!--              @change="setEmail"-->
            <!--            />-->

            <v-autocomplete
              :loading="curatorLoading"
              v-model="curator"
              label="Куратор цикла"
              placeholder="Выберите значение из списка"
              :items="employeesList"
              item-text="employeeInfo"
              hide-no-data
              hide-selected
              hide-details
              return-object
              clearable
              @change="setCurator"
            />
          </div>

          <div class="text accent--text" v-else>
            <div v-if="headTeacher">
              <strong>Заведующий учебной частью</strong>
              <p>{{ headTeacher.emplId ? headTeacher.employeeInfo : '-' }}</p>
            </div>

            <!--            <div v-if="email">-->
            <!--              <strong>E-mail заведующего учебной частью (заведующего кафедрой)</strong>-->
            <!--              <p>{{ email }}</p>-->
            <!--            </div>-->

            <div v-if="curator">
              <strong>Куратор цикла</strong>
              <p>{{ curator.emplId ? curator.employeeInfo : '-' }}</p>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <CycleAttributesDialog
      :dialog="cycleAttributesDialog"
      :disabled="isCycleCanceled"
      :loading="saveCycleAttributesLoading"
      :cycleAttributes="cycleAttributes"
      @saveCycleAttributes="saveCycleAttributes"
      @closeDialog="closeDialog"
    />
  </div>
</template>

<script>
import { cycleApi, integrationApi, reportApi } from '@/api'
import { mapGetters } from 'vuex'
import download from '@/plugins/download'
import { email, maxLength } from 'vuelidate/lib/validators'
import { Employee } from '@/models'
import ReportMenu from '@/components/cycles/ReportMenu'
import CycleAttributesDialog from '@/components/cycles/cycle/main/dialogs/CycleAttributes'

export default {
  name: 'Main',

  metaInfo: {
    title: 'Основные настройки цикла'
  },

  components: {
    ReportMenu,
    CycleAttributesDialog
  },

  async created() {
    await this.getEmployeesList()
    await this.getHeadTeacher()
    await this.getCurator()
    // Todo: вернуть Email, если он будет нужен
    // await this.getEmail()
    this.dataLoaded = true
  },

  data: () => ({
    dataLoaded: false,
    sendToAxLoading: false,

    cycleAttributes: null,
    cycleAttributesLoading: false,
    cycleAttributesDialog: false,
    saveCycleAttributesLoading: false,
    listForMoodleLoading: false,

    employeesList: [],
    headTeacher: null,
    headTeacherLoading: false,
    // email: null,
    // emailLoading: false,
    curator: null,
    curatorLoading: false
  }),

  // validations() {
  //   return {
  //     email: {
  //       email,
  //       maxLength: maxLength(80)
  //     }
  //   }
  // },

  computed: {
    ...mapGetters('user', ['isAdmin', 'isSuperAdmin']),
    ...mapGetters('cycle', ['cycleId', 'cycleNum', 'isCycleCanceled', 'factDist']),

    disabledSendToAxBtn() {
      return this.factDist === false
    },

    sendToAxBtnTitle() {
      if (!this.disabledSendToAxBtn) return null

      return 'Для выгрузки слушателей в УУ необходимо распределить часы в фактическом расписании согласно программе'
    }

    // emailErrors() {
    //   const errors = []
    //   if (!this.$v.email.$dirty) return errors
    //   !this.$v.email.email && errors.push('E-mail не валидный')
    //   !this.$v.email.maxLength && errors.push('Не больше 80 символов')
    //   return errors
    // }
  },

  methods: {
    goToListenersPage() {
      const cycle = { cycleId: this.cycleId, cycleNum: this.cycleNum }
      this.$router.push({ name: 'listeners', params: { cycle: cycle } })
    },

    async getEmployeesList() {
      try {
        const data = await cycleApi.getEmployeesListByCycleId(this.cycleId)
        this.employeesList = data.map(el => Employee.buildFromAPI(el))
      } catch (e) {
        console.log(e.status)
      }
    },

    async getHeadTeacher() {
      try {
        const data = await cycleApi.getHeadTeacher(this.cycleId)
        this.headTeacher = Employee.buildFromAPI(data) || null
      } catch (e) {
        console.log(e.status)
      }
    },

    async setHeadTeacher() {
      try {
        this.headTeacherLoading = true

        this.headTeacher
          ? await cycleApi.setHeadTeacher(this.cycleId, this.headTeacher)
          : await cycleApi.deleteHeadTeacher(this.cycleId)
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка сохранения заведующего учебной частью')
      } finally {
        this.headTeacherLoading = false
      }
    },

    async getCurator() {
      try {
        const data = await cycleApi.getCurator(this.cycleId)
        this.curator = Employee.buildFromAPI(data) || null
      } catch (e) {
        console.log(e.status)
      }
    },

    async setCurator() {
      try {
        this.curatorLoading = true

        this.curator
          ? await cycleApi.setCurator(this.cycleId, this.curator)
          : await cycleApi.deleteCurator(this.cycleId)
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка сохранения куратора')
      } finally {
        this.curatorLoading = false
      }
    },

    async getDpoListenerListForMoodleExcel() {
      try {
        this.listForMoodleLoading = true

        const data = await reportApi.getDpoListenerListForMoodleExcel(this.cycleId)
        data && download(data, 'Данные о слушателях.xlsx')
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка генерации данных о слушателях')
      } finally {
        this.listForMoodleLoading = false
      }
    },

    // async getEmail() {
    //   try {
    //     const data = await cycleApi.getEmail(this.cycleId)
    //     this.email = data.email
    //   } catch (e) {
    //     console.log(e.status)
    //   }
    // },
    //
    // async setEmail() {
    //   try {
    //     if (!this.$v.email.$invalid) {
    //       this.emailLoading = true
    //
    //       const body = { email: this.email }
    //       await cycleApi.setEmail(this.cycleId, body)
    //     }
    //   } catch (e) {
    //     this.showErrorMessage(e, 'Ошибка сохранения Email')
    //   } finally {
    //     this.emailLoading = false
    //   }
    // },

    async sendToAx() {
      try {
        this.sendToAxLoading = true

        await integrationApi.sendToAx(this.cycleId)
        this.$toastr('success', '', 'Выгружено в учебное управление')
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка выгрузки в учебное управление')
      } finally {
        this.sendToAxLoading = false
      }
    },

    async mergeResultDoc() {
      try {
        this.sendToAxLoading = true

        await integrationApi.mergeResultDoc(this.cycleId)
        this.$toastr('success', '', 'Выгружено в учебное управление')
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка выгрузки в учебное управление')
      } finally {
        this.sendToAxLoading = false
      }
    },

    async getCycleAttributes() {
      try {
        this.cycleAttributesLoading = true

        this.cycleAttributes = await cycleApi.getCycleAttributes(this.cycleId)
        this.cycleAttributesDialog = true
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка получения атрибутов цикла')
      } finally {
        this.cycleAttributesLoading = false
      }
    },

    async saveCycleAttributes(body) {
      try {
        this.saveCycleAttributesLoading = true

        await cycleApi.sendCycleAttributes(this.cycleId, body)
        this.cycleAttributesDialog = false
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка сохранения атрибутов цикла')
      } finally {
        this.saveCycleAttributesLoading = false
      }
    },

    closeDialog() {
      this.cycleAttributesDialog = false
    }
  }
}
</script>

<style scoped>
.base-card .inputs {
  max-width: 870px;
  margin-top: 24px;
}

.base-card .text {
  margin-top: 18px;
}

.base-card .text div {
  margin-bottom: 12px;
  font-size: 15px;
}

.base-card .text div:last-child, p {
  margin-bottom: 0;
}

.cycle-buttons {
  gap: 8px;
}
</style>