<template>
	<v-row justify="center">
		<v-dialog :value="dialog" max-width="600" @input="closeDialog">
			<v-card class="dialog-card">
				<v-card-title class="headline accent--text pb-4">Атрибуты цикла</v-card-title>

				<v-card-text outlined tile class="border-top">
					<v-container>
						<v-row>
							<v-col cols="12" class="pb-0 pt-5 px-0">
                <div v-if="!disabled">
                  <v-text-field
                    v-model="attributes.learningContingent"
                    class="required"
                    label="Категория обучающихся"
                    placeholder="врачи-анастезиологи"
                    clearable
                    :error-messages="errorMessages.learningContingent"
                    @input="$v.attributes.learningContingent.$touch()"
                    @blur="$v.attributes.learningContingent.$touch()"
                  />

                  <v-text-field
                    v-model="attributes.typeRetraining"
                    class="required"
                    label="Тип обучения"
                    placeholder="по теме"
                    clearable
                    :error-messages="errorMessages.typeRetraining"
                    @input="$v.attributes.typeRetraining.$touch()"
                    @blur="$v.attributes.typeRetraining.$touch()"
                  />
                </div>

                <div class="text accent--text" v-else>
                  <div>
                    <strong>Категория обучающихся</strong>
                    <p>{{ attributes.learningContingent || '-' }}</p>
                  </div>

                  <div>
                    <strong>Тип обучения</strong>
                    <p>{{ attributes.typeRetraining || '-' }}</p>
                  </div>
                </div>
              </v-col>
            </v-row>
					</v-container>
				</v-card-text>				

        <v-card-actions class="px-6">
          <v-spacer></v-spacer>
          <v-btn text color="accent" @click="closeDialog">
            {{ disabled ? 'Понятно' : 'Отмена' }}
          </v-btn>

          <v-btn
            v-if="!disabled"
            :disabled="$v.$invalid"
						:loading="loading"
            color="success"
            text
            @click="saveCycleAttributes"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { required, maxLength } from 'vuelidate/lib/validators'
import { CycleAttributes } from '@/models'
import { setValidateMessages } from '@/scripts/validation'

export default {
	props: {
		dialog: Boolean,
		disabled: Boolean,
		loading: Boolean,
    cycleAttributes: Object
	},

	data: () => ({
    attributes: new CycleAttributes()
	}),

  validations() {
    return {
      attributes: {
        learningContingent: { required, maxLength: maxLength(200) },
        typeRetraining: { required, maxLength: maxLength(150) }
      }
    }
  },

	computed: {
    ...mapGetters('cycle', ['cycleId']),

    errorMessages() {
      const allErrors = {}

      Object.keys(this.$v.attributes.$params).forEach(key => {
        allErrors[key] = setValidateMessages(this.$v.attributes[key])
      })

      return allErrors
    }
	},

	methods: {
		saveCycleAttributes() {
      this.$emit('saveCycleAttributes', this.attributes.getForAPI())
    },

    resetModal() {
      this.$v.$reset()
      this.attributes = new CycleAttributes()
    },

    closeDialog() {
      this.resetModal()
			this.$emit('closeDialog')
		}
	},

  watch: {
    cycleAttributes(val) {
      this.attributes = val ? CycleAttributes.buildFromAPI(val) : new CycleAttributes()
    }
  }
}
</script>

<style scoped>
.text div {
  margin-bottom: 12px;
  font-size: 15px;
}

.text div:last-child, p {
  margin-bottom: 0;
}
</style>