import { formatDateReverseToISO } from '@/scripts'
import { helpers } from "vuelidate/lib/validators"

const cyrillic = helpers.regex('cyrillic', /^[ а-яА-ЯёЁ-]*$/)
const dateFormat = helpers.regex('dateFormat', /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/)
const snilsFormat = helpers.regex('snilsFormat', /^\d{3}-\d{3}-\d{3} \d{2}$/)
const setOfSymbols = helpers.regex('setOfSymbols', /^[ а-яА-ЯёЁA-Za-z0-9.,-:;№\_\(\)]*$/)
const cellularPhone = helpers.regex('cellularPhone', /^\+ \[0-9]{16}$/)
const meanScore = helpers.regex('meanScore', /^[3-5]{1}\.[0-9]{3}$/)
const regexFrom0To99 = helpers.regex('regexFrom0To99', /^([0-9]|[1-9][0-9])$/)
const regexFrom0To999 = helpers.regex('regexFrom0To999', /^(?:[1-9][0-9]{2}|[1-9][0-9]|[0-9])$/)
const regexFrom0To9999 = helpers.regex('regexFrom0To9999', /^(?:[1-9][0-9]{3}|[1-9][0-9]{2}|[1-9][0-9]|[0-9])$/)
const regexFrom1To60 = helpers.regex('regexFrom1To60', /^([1-9]|[1-5][0-9]|60)$/)
const regexFrom10To60 = helpers.regex('regexFrom10To60', /^([1-5][0-9]|60)$/)
const regexFrom0To5000 = helpers.regex('regexFrom0To5000', /^(?:[1-4][0-9]{1,3}|[1-9][0-9]{0,2}||[0-9]|5000)$/)
const twoNumbersRegex = helpers.regex('twoNumbersRegex', /^\d$|^[1-9]\d?$/)
const scheduleHours = helpers.regex('scheduleHours', /^[1-8]$/)

const stopSymbols = string => {
  if (!string) return true
  return (!/["']/.test(string))
}

const checkSnilsSum = snils => {
  let sum = 0
  let checkDigit = 0

  if (!!snils) {
    snils = snils.replace(/-/g, '')
  } else {
    return true
  }

  for (let i = 0; i < 9; i++) {
    sum += parseInt(snils[i]) * (9 - i)
  }

  if (sum < 100) {
    checkDigit = sum
  } else if (sum > 101) {
    checkDigit = parseInt(sum % 101)
    if (checkDigit === 100) {
      checkDigit = 0
    }
  }

  return checkDigit === parseInt(snils.slice(-2))
}

const checkMinAge = date => {
  if (date && dateFormat(date)) {
    const now = new Date()

    let y = now.getFullYear() - 14
    let m = now.getMonth() + 1
    let d = now.getDate()

    if (m < 10) m = `0${m}`
    if (d < 10) d = `0${d}`

    const past18Years = new Date(`${y}.${m}.${d}`)
    const formDate = new Date(date.split('.').reverse().join('.'))
    return formDate <= past18Years
  }

  return true
}

const egeScore = value => +value === 0 || (+value >= 40 && +value <= 100)
const ebeScore = value => +value === 0 || (+value >= 40 && +value <= 100)

const timeIntervalStringChecker = string => {
  if (!string) return true
  if (!/^\d\d:\d\d-\d\d:\d\d$/.test(string)) return false
  const timeStrings = string.split('-')

  let [startHours, startMinutes] = timeStrings[0].split(':')
  let [endHours, endMinutes] = timeStrings[1].split(':')

  startHours = parseInt(startHours)
  startMinutes = parseInt(startMinutes)
  endHours = parseInt(endHours)
  endMinutes = parseInt(endMinutes)

  if (startHours > 23 || endHours > 23 || startMinutes > 59 || endMinutes > 59) {
    return false
  }

  // время для проверки берется "от начала времен"
  let timeFrom = new Date(0)
  let timeTo = new Date(0)

  // установка времени начала
  timeFrom.setHours(startHours)
  timeFrom.setMinutes(startMinutes)

  // установка времени окончания
  timeTo.setHours(endHours)
  timeTo.setMinutes(endMinutes)

  return timeTo > timeFrom
}

const checkTimeFormat = string => {
  if (!string) return true
  if (!/^\d\d:\d\d$/.test(string)) return false

  let [hours, minutes] = string.split(':')

  hours = parseInt(hours)
  minutes = parseInt(minutes)

  return !(hours > 23 || minutes > 59)
}

const checkDateFromToInterval = (currentDate, fromDate, toDate) => {
  if (currentDate && dateFormat(currentDate) && fromDate && toDate) {
    const formatDate = new Date(formatDateReverseToISO(currentDate))
    const formatFromDate = new Date(fromDate)
    const formatToDate = new Date(toDate)

    return formatDate >= formatFromDate && formatDate <= formatToDate
  }

  return true
}

const checkValueInArray = (valuesArray, value) => !valuesArray.some(el => el === value)

const setValidateMessages = property => {
  const validations = property.$params
  const validationsKeys = Object.keys(validations)

  const errors = []
  if (property?.stopSymbols !== false && !property.$dirty) return errors

  validationsKeys.forEach(key => {
    switch (key) {
      case 'required':
        !property[key] && errors.push('Обязательное поле')
        break
      case 'maxLength':
        !property[key] && errors.push(`Не больше ${validations.maxLength?.max} символов`)
        break
      case 'minLength':
        !property[key] && errors.push(`Не меньше ${validations.minLength?.min} символов`)
        break
      case 'maxValue':
        !property[key] && errors.push(`Значение не больше ${validations.maxValue?.max}`)
        break
      case 'minValue':
        !property[key] && errors.push(`Значение не меньше ${validations.minValue?.min}`)
        break
      case 'dateFormat':
        !property[key] && errors.push('Дата указана некорректно')
        break
      case 'email':
        !property[key] && errors.push('E-mail невалидный')
        break
      case 'cyrillic':
        !property[key] && errors.push('Буквы кириллицы, пробел и дефис')
        break
      case 'stopSymbols':
        !property[key] && errors.push('Запрещённые символы')
        break
      case 'snilsFormat': case 'checkSnilsSum':
        !property[key] && errors.push('СНИЛС невалидный')
        break
      case 'checkMinAge':
        !property[key] && errors.push('Минимальный возраст обучения ДПО 14 лет')
        break
      case 'timeIntervalStringChecker':
        !property[key] && errors.push('От 00:00 до 23:59')
        break
      case 'regexFrom0To99':
        !property[key] && errors.push('От 0 до 99')
        break
      case 'regexFrom0To999':
        !property[key] && errors.push('От 0 до 999')
        break
      case 'regexFrom0To5000':
        !property[key] && errors.push('От 0 до 5000')
        break
      case 'regexFrom0To9999':
        !property[key] && errors.push('От 0 до 9999')
        break
      case 'scheduleHours':
        !property[key] && errors.push('От 1 до 8')
        break
    }
  })

  return errors
}

export {
  cyrillic,
  dateFormat,
  snilsFormat,
  setOfSymbols,
  cellularPhone,
  meanScore,
  regexFrom0To99,
  regexFrom0To999,
  regexFrom0To9999,
  regexFrom1To60,
  regexFrom10To60,
  regexFrom0To5000,
  checkSnilsSum,
  checkMinAge,
  egeScore,
  ebeScore,
  twoNumbersRegex,
  scheduleHours,
  stopSymbols,
  timeIntervalStringChecker,
  checkDateFromToInterval,
  checkTimeFormat,
  checkValueInArray,
  setValidateMessages
}